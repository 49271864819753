import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout";

const Parteum = () => {
  return (
    <Layout>
      <Head title="Parteum" />
      <h3>Parteum</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Trenčín, Slovakia
      </h4> */}
      <p>
        <OutboundLink href="https://ello.co/parteum">Ello</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15424390/ello-optimized-f78fb187.jpghttps://assets2.ello.co/uploads/asset/attachment/15875572/ello-optimized-5104ccdf.jpg"
        alt="Parteum art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15862022/ello-optimized-a0b0c512.jpg"
        alt="Parteum art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15840489/ello-optimized-88aa2ac4.jpg"
        alt="Parteum art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15837318/ello-optimized-7af132af.jpg"
        alt="Parteum art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15834348/ello-optimized-1450ab03.jpg"
        alt="Parteum art"
      />
    </Layout>
  );
};

export default Parteum;
